<template>
  <div>
    <div class="row">
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <a-card>
          <template slot="title">
            <strong>Settings App</strong>
            <a-button
              class="btn btn-outline-primary ml-3"
              :loading="loading"
              @click="submit('ruleForm')"
            >
              Save Changes
            </a-button>
          </template>
          <div class="row mt-1">
            <div class="col-lg-12 col-md-12 col-sm-12">
              <a-form-model
                ref="ruleForm"
                :model="form"
                :rules="rules"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
              >
                <a-form-model-item
                  style="margin: unset;"
                  ref="nama"
                  label="Nama"
                  prop="nama"
                >
                  <a-input
                    v-model="form.nama"
                    @blur="
                      () => {
                        $refs.nama.onFieldBlur();
                      }
                    "
                  />
                </a-form-model-item>
                <a-form-model-item
                  style="margin: unset;"
                  ref="alamat"
                  label="Alamat"
                  prop="alamat"
                >
                  <a-input
                    v-model="form.alamat"
                    @blur="
                      () => {
                        $refs.alamat.onFieldBlur();
                      }
                    "
                  />
                </a-form-model-item>
                <a-form-model-item
                  style="margin: unset;"
                  ref="telp"
                  label="Telp"
                  prop="telp"
                >
                  <a-input
                    v-model="form.telp"
                    @blur="
                      () => {
                        $refs.telp.onFieldBlur();
                      }
                    "
                  />
                </a-form-model-item>
                <a-form-model-item
                  style="margin: unset;"
                  ref="kota"
                  label="Kota"
                  prop="kota"
                >
                  <a-input
                    v-model="form.kota"
                    @blur="
                      () => {
                        $refs.kota.onFieldBlur();
                      }
                    "
                  />
                </a-form-model-item>
                <a-form-model-item
                  style="margin: unset;"
                  ref="faktur_prefix"
                  label="Faktur Prefix"
                  prop="faktur_prefix"
                >
                  <a-input
                    v-model="form.faktur_prefix"
                    @blur="
                      () => {
                        $refs.faktur_prefix.onFieldBlur();
                      }
                    "
                  />
                </a-form-model-item>
                <a-form-model-item
                  style="margin: unset;"
                  ref="footer"
                  label="Footer"
                  prop="footer"
                >
                  <a-textarea
                    style="width: 200px;"
                    v-model="form.footer"
                    placeholder="Isi dari Footer di printer"
                    :auto-size="{ minRows: 3, maxRows: 4 }"
                    @blur="
                      () => {
                        $refs.footer.onFieldBlur();
                      }
                    "
                  />
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
        </a-card>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <a-card title="Rules">
          <div class="row" style="padding: 10px;">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <label for="setoran" class="mr-2">Setoran:</label>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <a-switch
                        :key="switchCKey"
                        :defaultChecked="form.setoran == 1"
                        @change="(e) => changeSwitch(e, 'setoran')"
                      >
                        <a-icon slot="checkedChildren" type="check" />
                        <a-icon slot="unCheckedChildren" type="close" />
                      </a-switch>
                    </div>
                  </div>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <label for="penarikan" class="mr-2">Penarikan:</label>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <a-switch
                        :key="switchCKey"
                        :defaultChecked="form.penarikan == 1"
                        @change="(e) => changeSwitch(e, 'penarikan')"
                      >
                        <a-icon slot="checkedChildren" type="check" />
                        <a-icon slot="unCheckedChildren" type="close" />
                      </a-switch>
                    </div>
                  </div>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <label for="angsuran" class="mr-2">Angsuran:</label>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <a-switch
                        :key="switchCKey"
                        :defaultChecked="form.angsuran == 1"
                        @change="(e) => changeSwitch(e, 'angsuran')"
                      >
                        <a-icon slot="checkedChildren" type="check" />
                        <a-icon slot="unCheckedChildren" type="close" />
                      </a-switch>
                    </div>
                  </div>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                  <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <label for="saldo" class="mr-2">Saldo:</label>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <a-switch
                        :key="switchCKey"
                        :defaultChecked="form.saldo == 1"
                        @change="(e) => changeSwitch(e, 'saldo')"
                      >
                        <a-icon slot="checkedChildren" type="check" />
                        <a-icon slot="unCheckedChildren" type="close" />
                      </a-switch>
                    </div>
                  </div>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <label for="saldo" class="mr-2">NIK:</label>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <a-switch
                        :key="switchCKey"
                        :defaultChecked="form.nik == 1"
                        @change="(e) => changeSwitch(e, 'nik')"
                      >
                        <a-icon slot="checkedChildren" type="check" />
                        <a-icon slot="unCheckedChildren" type="close" />
                      </a-switch>
                    </div>
                  </div>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <label for="saldo" class="mr-2">Rekening:</label>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <a-switch
                        :key="switchCKey"
                        :defaultChecked="form.rekening == 1"
                        @change="(e) => changeSwitch(e, 'rekening')"
                      >
                        <a-icon slot="checkedChildren" type="check" />
                        <a-icon slot="unCheckedChildren" type="close" />
                      </a-switch>
                    </div>
                  </div>
                </div>
                <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3 mt-2">
                  <div class="row">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <label for="carinama" class="">Cari Nama:</label>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                      <a-switch
                        :key="switchCKey"
                        :defaultChecked="form.carinama == 1"
                        @change="(e) => changeSwitch(e, 'carinama')"
                      >
                        <a-icon slot="checkedChildren" type="check" />
                        <a-icon slot="unCheckedChildren" type="close" />
                      </a-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import moment from 'moment'

export default {
  name: 'editprofile',
  created() {
    this.getData()
    // this.getMaster()
  },
  data() {
    // const validatePass = (rule, value, callback) => {
    //   // if (this.action === 'Create') {
    //   //   if (this.form.password_confirmation !== '') {
    //   //     this.$refs.ruleForm.validateField('checkPass')
    //   //   }
    //   //   callback()
    //   // } else {
    //   if (value === '') {
    //     callback(new Error('Please input the password'))
    //   } else {
    //     if (this.form.password_confirmation !== '') {
    //       this.$refs.ruleForm.validateField('checkPass')
    //     }
    //     callback()
    //   }
    //   // }
    // }
    // const validatePass2 = (rule, value, callback) => {
    //   // if (this.action === 'Create') {
    //   //   if (value !== this.form.password) {
    //   //     callback(new Error("Password doesn't match!"))
    //   //   } else {
    //   //     callback()
    //   //   }
    //   // } else {
    //   if (value === '') {
    //     callback(new Error('Please input the password again'))
    //   } else {
    //     if (value !== this.form.password) {
    //       callback(new Error("Password doesn't match!"))
    //     } else {
    //       callback()
    //     }
    //   }
    //   // }
    // }
    return {
      labelCol: { span: 6 },
      wrapperCol: { span: 15 },
      user: [],
      datarole: [],
      databpr: [],
      roletags: false,
      editdata: {},
      action: 'Create',
      form: {
        setoran: 0,
        penarikan: 0,
        angsuran: 0,
        carinama: 0,
        saldo: 0,
        nik: 0,
        rekening: 0,
        nama: '',
        alamat: '',
        telp: '',
        kota: '',
        faktur_prefix: '',
        footer: '',
      },
      rules: {
        nama: [{ required: true, message: 'Please input nama!' }],
        alamat: [{ required: true, message: 'Please input alamat!' }],
        telp: [{ required: true, message: 'Please input telp!' }],
        kota: [{ required: true, message: 'Please input kota!' }],
        footer: [{ required: true, message: 'Please input footer!' }],
        // periode: [{ required: true, message: 'Please input periode!' }],
      },
      loading: false,
      visible: false,
      datenow: null,
      switchCKey: 0,
    }
  },
  methods: {
    moment,
    // async getMaster() {
    //   var resp = await lou.readMaster('id_role')
    //   this.datarole = resp.data
    // },
    async getData() {
      var res = await lou.readMaster('setting', false, false)
      if (res) {
        console.log('res.data.length', res.data.length)
        if (res.data.length !== 0) {
          this.action = 'Update'
          this.showModal(res.data[0])
        } else {
          this.action = 'Create'
        }
      }
    },
    changeSwitch(e, to) {
      var val = e ? 1 : 0
      this.form[to] = val
    },
    selectDate(selectedDates, dateString, input) {
      // console.log('selectedDates', selectedDates)
      // console.log('dateString', dateString)
      // console.log('input', input)
      if (selectedDates !== null) {
        this.form[input] = dateString
      } else {
        this.form[input] = null
      }
    },
    userAppSelect(e) {
      // console.log('e', e)
      var temp = []
      for (let m = 0; m < e.length; m++) {
        const element = e[m]
        temp.push({ id_app: element })
      }
      // console.log('temp', temp)
      this.form.appLists = temp
    },
    async showModal(data = {}) {
      // console.log('data', data)
      this.editdata = data
      // var resbpr = await lou.customUrlGet('auth/sandibpr', false, true)
      // this.databpr = resbpr.data
      if (this.action === 'Update') {
        this.form.id = data.id
        this.form.setoran = data.setoran
        this.form.penarikan = data.penarikan
        this.form.angsuran = data.angsuran
        this.form.carinama = data.carinama
        this.form.saldo = data.saldo
        this.form.nik = data.nik
        this.form.rekening = data.rekening
        this.form.nama = data.nama
        this.form.alamat = data.alamat
        this.form.telp = data.telp
        this.form.kota = data.kota
        this.form.faktur_prefix = data.faktur_prefix
        this.form.footer = data.footer
        // console.log('this.form.selectedAppLists', this.form.selectedAppLists)
      } else {
        this.form.setoran = 0
        this.form.angsuran = 0
        this.form.penarikan = 0
        this.form.carinama = 0
        this.form.saldo = 0
        this.form.nik = 0
        this.form.rekening = 0
        this.form.nama = ''
        this.form.alamat = ''
        this.form.telp = ''
        this.form.kota = ''
        this.form.faktur_prefix = ''
        this.form.footer = ''
      }
      this.switchCKey += 1
    },
    handleCancel() {
      this.visible = false
    },
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log('this.form.appLists', this.form.appLists)
          var fd = {
            bpr_id: 0,
            setoran: this.form.setoran,
            penarikan: this.form.penarikan,
            angsuran: this.form.angsuran,
            carinama: this.form.carinama,
            saldo: this.form.saldo,
            nik: this.form.nik,
            rekening: this.form.rekening,
            nama: this.form.nama,
            alamat: this.form.alamat,
            telp: this.form.telp,
            kota: this.form.kota,
            faktur_prefix: this.form.faktur_prefix,
            footer: this.form.footer,
          }
          if (this.action === 'Create') {
            await lou.createMaster('setting', fd, true, false)
          } else {
            fd.id = this.form.id
            await lou.updateMaster('setting', fd, true, false)
          }
          this.getData()
          this.visible = false
          this.resetForm(formName)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
  },
}
</script>

<style></style>
